<template>
  <main class="home">
    <section class="progress">
      <p class="stage">3</p>
      <div class="bar"><span></span></div>
      <p class="max">4</p>
    </section>
    <section class="header">
      <h1>Key details</h1>
      <p>Dive deeper now or update later in the Manager (optional)</p>
    </section>
    <div
      class="wrapper"
      v-for="(event, eventIndex) in tour.events"
      :key="eventIndex"
    >
      <section
        class="divisions"
        id="divisions"
        :class="{ error: erronousSections.includes('divisions') }"
      >
        <h5>Divisions</h5>
        <div class="existing-divisions">
          <TheTjingDivisionEditor
            v-for="division in tour.divisions"
            :key="division.type"
            :division="division"
            :tourPassAllowed="false"
            :usedDivisions="usedDivisions"
            :availableDivisions="availableDivisions"
            :builderMode="true"
            :currencies="currencies"
            @updateDivision="updateDivision($event)"
            @deleteDivision="deleteDivision(division.type)"
          />
        </div>
        <div class="new-divisions">
          <TheTjingDivisionEditor
            :tourPassAllowed="false"
            :usedDivisions="usedDivisions"
            :availableDivisions="availableDivisions"
            :addMode="true"
            :builderMode="true"
            :currencies="currencies"
            @addDivision="addDivision($event)"
          />
        </div>
        <p class="mt-4 mb-0">
          Stages and criteria can be added to divisions in the Manager after you
          have saved your event.
        </p>
      </section>

      <section class="misc-settings">
        <div class="max-players">
          <h5>Max players</h5>
          <ValueSelector
            :values="maxSpotsOptions(event).values"
            :colors="maxSpotsOptions(event).colors"
            :title="''"
            @updatedValue="
              $store.dispatch('updateEventStringValue', {
                value: $event.value,
                property: 'maxSpots',
                eventIndex: eventIndex,
              })
            "
          />
        </div>
        <div class="group-size">
          <h5>Group size</h5>
          <div
            class="item"
            v-for="n in 4"
            :key="n"
            :class="{ active: n + 1 == event.groupSize }"
            @click="
              $store.dispatch('updateEventStringValue', {
                value: n + 1,
                property: 'groupSize',
                eventIndex: 0,
              })
            "
          >
            {{ n + 1 }}
          </div>
        </div>
        <div class="wildcards">
          <h5>Wildcards</h5>

          <ValueSelector
            :values="wildcardOptions(event).values"
            :colors="wildcardOptions(event).colors"
            :title="''"
            @updatedValue="
              $store.dispatch('updateEventStringValue', {
                value: $event.value,
                property: 'maxWildcards',
                eventIndex: eventIndex,
              })
            "
          />
        </div>
      </section>

      <section class="registration-open">
        <div class="sub-section left">
          <h5>Registration opening date</h5>
          <TjingDateTimePicker
            class="datepicker"
            :canBeCleared="true"
            :readOnly="false"
            :options="registrationOpensTime"
            :dateTime="event.registrationOpenAt"
            @update="
              updateEventTimeField($event, eventIndex, 'registrationOpenAt')
            "
          />
        </div>
        <div class="sub-section auto-open">
          <div class="header">
            <h6>Automatically open</h6>
            <DefaultToggle
              :value="event.autoOpenRegistration"
              :disabled="event.registrationOpenAt == null"
              @input="
                $store.dispatch('updateBoolOnEvent', {
                  property: 'autoOpenRegistration',
                  eventIndex: eventIndex,
                  value: $event,
                })
              "
            />
          </div>

          <p>
            When enabled Tjing will open registration for you based on the
            registration opening date you’ve set.
          </p>
        </div>
      </section>
      <section class="registration-type">
        <h5>Registration type</h5>
        <DefaultRadioButton
          :groupname="'regtype'"
          :value="tour.registrationMethod"
          :options="{
            title: 'Waiting list',
            value: 'LIST',
            description:
              'Standard registration where players are placed on a waiting list and spots are granted by the TD.',
          }"
          @change="$store.dispatch('updateRegistrationType', 'LIST')"
        />

        <DefaultRadioButton
          :groupname="'regtype'"
          :value="tour.registrationMethod"
          :options="{
            title: 'Groups',
            value: 'GROUPS',
            description:
              'A more casual registration where players choose their own group to play in (note: groups can still be adjusted by the TD).',
          }"
          @change="$store.dispatch('updateRegistrationType', 'GROUPS')"
        />

        <div class="registration-messages">
          <b>Registration notes</b>
          <DefaultToggle
            :value="tour.allowRegistrationMessage"
            @input="$store.dispatch('updateRegistrationMessage', $event)"
          />
          <p>
            Allows players to include a note when registering for an event. Only
            event admins can see notes.
          </p>
        </div>
      </section>
      <section class="waitinglist-type">
        <h5>Waiting list type</h5>
        <div class="content">
          <DefaultRadioButton
            class="mb-4"
            groupname="waitinglist-type"
            :value="event.waitinglistType"
            @change="changeWaitinglistType(eventIndex, 'DIVISION')"
            :options="{
              title: 'Separated divisions',
              value: 'DIVISION',
              description: 'Each division has its own waiting list.',
            }"
          />
          <DefaultRadioButton
            groupname="waitinglist-type"
            :value="event.waitinglistType"
            @change="changeWaitinglistType(eventIndex, 'COMBINED')"
            :options="{
              title: 'Combined divisions',
              value: 'COMBINED',
              description:
                'All players and divisions are placed in one waiting list.',
            }"
          />
        </div>
      </section>
      <section
        class="check-in"
        :class="{
          error: erronousSections.includes('check-in'),
          expanded: globalSettings.checkInEnabled,
        }"
      >
        <h5
          @click="
            globalSettings.checkInEnabled = !globalSettings.checkInEnabled
          "
        >
          Check in
          <span
            ><Chevron2
              :direction="globalSettings.checkInEnabled ? 'up' : 'down'"
          /></span>
        </h5>
        <slide-up-down :active="globalSettings.checkInEnabled" :duration="300">
          <div class="open">
            <h6>Opens</h6>

            <TjingDateTimePicker
              class="datepicker"
              :canBeCleared="true"
              :readOnly="false"
              :options="checkInStartsTime"
              :dateTime="event.checkInStartsAt"
              @update="
                updateEventTimeField($event, eventIndex, 'checkInStartsAt')
              "
            />
          </div>
          <div class="closes">
            <h6>Closes</h6>
            <TjingDateTimePicker
              class="datepicker"
              :canBeCleared="true"
              :readOnly="false"
              :options="checkInEndsTime"
              :dateTime="event.checkInEndsAt"
              @update="
                updateEventTimeField($event, eventIndex, 'checkInEndsAt')
              "
            />
          </div>
        </slide-up-down>
      </section>
      <section
        class="pdga-tier"
        :class="{ expanded: globalSettings.pdgaTypeEnabled }"
      >
        <h5>
          PDGA sanctioned
          <DefaultToggle
            v-model="globalSettings.pdgaTypeEnabled"
            @input="togglePDGASanctioned($event)"
          />
        </h5>
        <slide-up-down :active="globalSettings.pdgaTypeEnabled" :duration="300">
          <DefaultRadioButton
            v-for="type in pdgaEventTypes"
            :key="type.type"
            :groupname="'pdgatier'"
            :value="event.pdgaType"
            :options="{
              title: type.name,
              value: type.type,
            }"
            @change="
              $store.dispatch('updateEventPDGAType', {
                eventIndex: eventIndex,
                pdgaType: type.type,
              })
            "
          />
        </slide-up-down>
      </section>

      <section class="nav-buttons">
        <div class="tip">
          <span>
            <Tip />
          </span>

          <p>
            Everything can be edited in the Manager after your event has been
            saved.
          </p>
        </div>
        <StandardBorderedButton
          title="Back"
          :fluid="false"
          :backwardsIcon="true"
          :desktopFluidity="true"
          @click="$router.go(-1)"
        />
        <StandardSolidButton
          class="forwards"
          title="Next"
          :fluid="false"
          :forwardIcon="true"
          :desktopFluidity="true"
          @click="validateAndProceed()"
        />
      </section>
      <section class="start-over" @click="$refs.resetmodal.open()">
        Reset and start over
      </section>
    </div>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="resetmodal"
      hide-close-button
    >
      <h3>Reset</h3>
      <p>
        This will remove any information you have added and take you back to the
        start.
      </p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.resetmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Reset"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
      </div>
    </sweet-modal>
  </main>
</template>

<script>
import moment from "moment";
import TheTjingDivisionEditor from "@/components/TheTjingDivisionEditor";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import ValueSelector from "@/components/ValueSelector";
import SlideUpDown from "vue-slide-up-down";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";
import Tip from "@/assets/icons/Tip";
import Chevron2 from "@/assets/icons/Chevron2";

export default {
  name: "SingleEventSettings",
  components: {
    TheTjingDivisionEditor,
    Tip,
    StandardSolidButton,
    StandardBorderedButton,
    DefaultRadioButton,
    TjingDateTimePicker,
    DefaultToggle,
    ValueSelector,
    SlideUpDown,
    Chevron2,
  },
  data() {
    return {
      erronousSections: [],
    };
  },

  computed: {
    validCheckInTimes() {
      let valid = true;

      this.tour.events.forEach((event) => {
        if (event.checkInStartsAt == null && event.checkInEndsAt != null)
          valid = false;
        if (event.checkInEndsAt == null && event.checkInStartsAt != null)
          valid = false;
      });

      return valid;
    },
    firstPoolStartTime() {
      let time = moment().add(10, "years");

      this.tour.events.forEach((event) => {
        event.rounds.forEach((round) => {
          round.pools.forEach((pool) => {
            if (moment(pool.date).isBefore(time)) {
              time = moment(pool.date);
            }
          });
        });
      });

      return time;
    },
    checkInStartsTime() {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: this.tour.events[0].checkInEndsAt,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    checkInEndsTime() {
      return {
        minDateTime: this.tour.events[0].checkInStartsAt,
        maxDateTime: moment().add(1, "years"),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    registrationOpensTime() {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: this.firstPoolStartTime,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    currencies() {
      return this.$store.getters.tour.currencies;
    },
    availableDivisions() {
      return this.$store.getters.tour.divisionTypes;
    },
    pdgaEventTypes() {
      return this.$store.getters.tour.pdgaEventTypes;
    },
    usedDivisions() {
      let usedDivisions = [];

      this.$store.getters.tour.tour.divisions.forEach((division) => {
        usedDivisions.push(division.type);
      });
      return usedDivisions;
    },
    tour() {
      return this.$store.getters.tour.tour;
    },
    globalSettings() {
      return this.$store.getters.tour.oneOffSettings;
    },
  },
  methods: {
    changeWaitinglistType(eventIndex, value) {
      this.$store.dispatch("changeWaitinglistType", {
        eventIndex,
        value,
      });
    },
    toggleCheckInEnabled() {
      this.$store.dispatch("updateEventTimeField", {
        eventIndex: 0,
        property: "checkInEndsAt",
        date: null,
      });
      this.$store.dispatch("updateEventTimeField", {
        eventIndex: 0,
        property: "checkInStartsAt",
        date: null,
      });
    },
    togglePDGASanctioned(value) {
      this.$store.dispatch("togglePDGASanctioned", {
        eventIndex: 0,
        value,
      });
    },
    resetAndClose(modalname) {
      this.$refs[modalname].close();

      this.$store.dispatch("resetBuilderState");
      this.$router.push({ name: "builder" });
    },
    markErrors() {
      if (this.tour.divisions.length == 0) {
        this.erronousSections.push("divisions");
      }

      if (this.validCheckInTimes == false) {
        this.erronousSections.push("check-in");
      }
    },
    validateAndProceed() {
      this.markErrors();
      if (this.erronousSections.length == 0) {
        this.$router.push({ name: "singleeventoptional" });
      } else {
        this.$scrollTo("#" + this.erronousSections[0]);
      }
    },
    maxSpotsOptions(event) {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 999; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == event.maxSpots || (event.maxSpots == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    wildcardOptions(event) {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 20; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == event.maxWildcards ||
            (event.maxWildcards == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    updateEventTimeField(date, eventIndex, property) {
      this.erronousSections = this.erronousSections.filter(
        (error) => error != "check-in"
      );

      if (property == "registrationOpenAt" && date == null) {
        this.$store.dispatch("updateBoolOnEvent", {
          property: "autoOpenRegistration",
          eventIndex: eventIndex,
          value: false,
        });
      }
      if (property == "registrationOpenAt" && date != null) {
        this.$store.dispatch("updateBoolOnEvent", {
          property: "autoOpenRegistration",
          eventIndex: eventIndex,
          value: true,
        });
      }
      this.$store.dispatch("updateEventTimeField", {
        eventIndex: eventIndex,
        property: property,
        date: moment(date).toISOString(),
      });
    },
    deleteDivision(division) {
      this.$store.dispatch("removeDivision", division);
    },
    updateDivision(division) {
      this.$store.dispatch("updateDivision", division);
    },
    addDivision(division) {
      this.erronousSections = this.erronousSections.filter(
        (error) => error != "divisions"
      );
      this.$store.dispatch("addDivision", division);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.start-over {
  margin-top: 64px;
  color: $strom;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: $dusk;
}
.registration-type,
.waitinglist-type {
  padding: 20px 20px 28px 20px;
  margin-top: 16px;
  border-radius: 6px;
  border: 1px solid $sleet;
  background: white;
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    > div {
      margin-left: 8px;
    }
  }
  .default-radio-buttons {
    margin-top: 16px;
  }

  .registration-messages {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    b {
      font-size: 14px;
    }
    p {
      margin-top: 12px;
      font-size: 14px;
    }
  }
}
.misc-settings {
  padding: 20px 20px 28px 20px;
  margin-top: 16px;
  background: white;
  border-radius: 6px;
  border: 1px solid $sleet;
  display: flex;
  flex-wrap: wrap;
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
  }
}
.wildcards,
.max-players {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .selector-container {
    margin-top: 12px;
  }
}
.wildcards,
.group-size {
  margin-top: 24px;
  padding-top: 22px;
  border-top: 1px solid $fog;
}
.group-size {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  p {
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin-top: 24px;
    @include Gilroy-Bold;
    margin-bottom: 0;
  }

  .item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blizzard;
    color: $blizzard;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 12px;
    @include Gilroy-Bold;
    transition: all ease 0.3s;
    font-size: 16px;

    &.active {
      border: 1px solid $twilight;
      background: $twilight;
      color: white;

      &:hover {
        color: white;
        cursor: default;
        border: 1px solid $twilight;
      }
    }

    &:hover {
      border: 1px solid $midnight;
      color: $midnight;
      cursor: pointer;
    }

    &.none {
      width: 64px;
    }
  }
}
.registration-open {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid $sleet;
  padding: 20px 20px 28px 20px;
  margin-top: 16px;
  border-radius: 6px;

  h5 {
    font-size: 16px;
    margin: 0;
  }

  .datepicker {
    margin-top: 12px;
  }

  .auto-open {
    margin-top: 24px;

    .header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      h6 {
        font-size: 14px;
        margin: 0;
      }

      h5 {
        font-size: 16px;
        @include Gilroy-Bold;
        margin: 0;
      }
    }
    p {
      margin-top: 14px;
      font-size: 14px;
    }
  }
}
.check-in {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  padding: 20px 20px 20px 20px;
  margin-top: 16px;
  box-shadow: 0 0 0 1px $sleet;
  border-radius: 6px;

  &.expanded {
    padding: 20px 20px 28px 20px;
  }
  > div {
    width: 100%;
    margin-top: 0px;
  }

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }

  h6 {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h5 {
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .closes {
    margin-top: 8px;
  }
}
.pdga-tier {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  padding: 20px 20px 20px 20px;
  margin-top: 24px;
  border: 1px solid $sleet;
  border-radius: 6px;

  &.expanded {
    padding: 20px 20px 28px 20px;
  }

  > div {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .default-radio-buttons {
      width: 50%;
      margin-top: 16px;

      &:nth-child(even) {
        padding-left: 30px;
      }
    }
  }

  h5 {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.nav-buttons {
  margin-top: 22px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tip {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: $sunrisemist;
    border: 1px solid #ffd97f;
    border-radius: 6px;
    margin-bottom: 24px;
    width: 100%;
    span {
      margin-right: 10px;
    }

    svg {
      height: 18px;
      width: 14px;
    }

    p {
      display: inline;
      margin: 0;
      font-size: 14px;
    }
  }
  button {
    width: 47%;
  }
}
.divisions {
  margin-top: 40px;
  background: white;
  padding: 20px 20px 28px 20px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px $sleet;

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }
  h5 {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .division-editor-container {
    margin-top: 12px;
  }
}
.wrapper {
  padding: 0 15px;
}
.progress {
  margin-top: 32px;
  background: none;
  padding: 0 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .stage {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-right: 12px;
  }
  .max {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-left: 12px;
  }
  .bar {
    width: 100%;
    background: $fog;
    height: 8px;
    border-radius: 4px;

    span {
      width: 75%;
      background: $ocean;
      display: flex;
      height: 8px;
      border-radius: 4px;
    }
  }
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 24px;
  }
  p {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .divisions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    h5 {
      width: 100%;
    }
    .existing-divisions,
    .new-divisions {
      width: 47%;
      margin-top: 8px;
    }
  }
  .progress {
    display: flex;
    justify-content: center;

    .bar {
      width: 50%;
    }
  }
  .misc-settings {
    margin-top: 32px;
    border-radius: 10px;
    padding: 0;
    display: flex;
    h5 {
      font-size: 16px;
      text-align: center;
      margin-bottom: 24px;
      margin-top: 20px;
    }

    .max-players {
      width: 30%;
      justify-content: center;
      .selector-container {
        margin: 0 0 24px 0;
      }
    }
    .group-size {
      width: 40%;
      border-right: 1px solid $fog;
      border-left: 1px solid $fog;
      border-top: none;
      padding: 0;
      margin: 0;
      justify-content: center;

      .item {
        margin: 0 6px 24px 6px;
        height: 40px;
        width: 40px;
        font-size: 16px;
      }
    }
    .wildcards {
      width: 30%;
      border-top: none;
      margin: 0;
      padding: 0;
      justify-content: center;
      .selector-container {
        margin: 0 0 24px 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .start-over {
    font-size: 16px;
  }
  .registration-type,
  .waitinglist-type {
    padding: 40px 48px 52px 40px;
    border-radius: 10px;
    margin-top: 32px;

    h5 {
      font-size: 20px;
    }
    .default-radio-buttons {
      margin-top: 32px;
    }

    .registration-messages {
      margin-top: 32px;
      border-top: 1px solid $fog;
      padding-top: 24px;

      p {
        font-size: 16px;
        width: 100%;
      }
      b {
        font-size: 16px;
      }
    }
  }
  .pdga-tier {
    padding: 40px 48px 40px 40px;
    border-radius: 10px;
    margin-top: 32px;

    &.expanded {
      padding: 40px 48px 52px 40px;
    }

    > div {
      width: 100%;
      .default-radio-buttons {
        width: 20%;
        margin-top: 32px;
        &:nth-child(even) {
          padding-left: 0;
        }
      }
    }
    h5 {
      font-size: 20px;
    }
  }
  .check-in {
    margin-top: 32px;
    border-radius: 10px;
    padding: 40px 48px 40px 40px;

    > div {
      display: flex;
    }

    &.expanded {
      padding: 40px 48px 52px 40px;
    }
    h5 {
      font-size: 20px;
      svg {
        cursor: pointer;
        width: 21px;
        height: 12px;
      }
    }

    .open {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      border-right: 1px solid $fog;

      h6 {
        margin: 0;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .datepicker {
        width: 60%;
      }
    }
    .closes {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      .datepicker {
        width: 60%;
      }
      h6 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }
  .misc-settings {
    margin-top: 32px;
    border-radius: 10px;
    padding: 0;
    display: flex;
    h5 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 32px;
      margin-top: 40px;
    }

    .max-players {
      width: 30%;
      justify-content: center;
      .selector-container {
        margin: 0 0 52px 0;
      }
    }
    .group-size {
      width: 40%;
      border-right: 1px solid $fog;
      border-left: 1px solid $fog;
      border-top: none;
      padding: 0;
      margin: 0;
      justify-content: center;

      .item {
        margin: 0 6px 52px 6px;
        height: 48px;
        width: 48px;
        font-size: 16px;
      }
    }
    .wildcards {
      width: 30%;
      border-top: none;
      margin: 0;
      padding: 0;
      justify-content: center;
      .selector-container {
        margin: 0 0 52px 0;
      }
    }
  }
  .registration-open {
    margin-top: 32px;
    border-radius: 10px;
    padding: 40px 48px 52px 40px;
    flex-direction: row;
    .left {
      width: 40%;
      border-right: 1px solid $fog;
      padding-right: 48px;
    }
    .datepicker {
      margin-top: 24px;
    }

    .auto-open {
      width: 60%;
      padding-left: 48px;
      margin-top: 0;
      .header {
        h6 {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
      }
    }

    h5 {
      font-size: 20px;
    }
  }

  .divisions {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 48px 52px 48px;
    margin-top: 100px;
    h5 {
      width: 100%;
      font-size: 20px;
    }

    .existing-divisions,
    .new-divisions {
      width: 50%;
      margin-top: 8px;
      .division-editor-container {
        max-width: unset;
        width: 100%;
      }
    }
    .existing-divisions {
      padding-right: 48px;
    }

    .new-divisions {
      padding-left: 48px;
    }
  }
  .progress {
    margin: auto;
    margin-top: 92px;
    width: 300px;
    padding: 0;

    .bar {
      width: 100%;
    }
  }
  .header {
    h1 {
      font-size: 64px;
      line-height: 64px;
      margin-top: 58px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
    }
  }
  .nav-buttons {
    margin-top: 64px;

    .tip {
      width: auto;
      order: 2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-size: 16px;
      }
      svg {
        margin-right: 8px;
        margin-bottom: 2px;
      }
    }
    .backward {
      order: 1;
      width: auto;
    }
    .forwards {
      order: 3;
    }
  }
}
</style>
