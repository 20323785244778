var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"home"},[_vm._m(0),_vm._m(1),_vm._l((_vm.tour.events),function(event,eventIndex){return _c('div',{key:eventIndex,staticClass:"wrapper"},[_c('section',{staticClass:"divisions",class:{ error: _vm.erronousSections.includes('divisions') },attrs:{"id":"divisions"}},[_c('h5',[_vm._v("Divisions")]),_c('div',{staticClass:"existing-divisions"},_vm._l((_vm.tour.divisions),function(division){return _c('TheTjingDivisionEditor',{key:division.type,attrs:{"division":division,"tourPassAllowed":false,"usedDivisions":_vm.usedDivisions,"availableDivisions":_vm.availableDivisions,"builderMode":true,"currencies":_vm.currencies},on:{"updateDivision":function($event){return _vm.updateDivision($event)},"deleteDivision":function($event){return _vm.deleteDivision(division.type)}}})}),1),_c('div',{staticClass:"new-divisions"},[_c('TheTjingDivisionEditor',{attrs:{"tourPassAllowed":false,"usedDivisions":_vm.usedDivisions,"availableDivisions":_vm.availableDivisions,"addMode":true,"builderMode":true,"currencies":_vm.currencies},on:{"addDivision":function($event){return _vm.addDivision($event)}}})],1),_c('p',{staticClass:"mt-4 mb-0"},[_vm._v(" Stages and criteria can be added to divisions in the Manager after you have saved your event. ")])]),_c('section',{staticClass:"misc-settings"},[_c('div',{staticClass:"max-players"},[_c('h5',[_vm._v("Max players")]),_c('ValueSelector',{attrs:{"values":_vm.maxSpotsOptions(event).values,"colors":_vm.maxSpotsOptions(event).colors,"title":''},on:{"updatedValue":function($event){return _vm.$store.dispatch('updateEventStringValue', {
              value: $event.value,
              property: 'maxSpots',
              eventIndex: eventIndex,
            })}}})],1),_c('div',{staticClass:"group-size"},[_c('h5',[_vm._v("Group size")]),_vm._l((4),function(n){return _c('div',{key:n,staticClass:"item",class:{ active: n + 1 == event.groupSize },on:{"click":function($event){return _vm.$store.dispatch('updateEventStringValue', {
              value: n + 1,
              property: 'groupSize',
              eventIndex: 0,
            })}}},[_vm._v(" "+_vm._s(n + 1)+" ")])})],2),_c('div',{staticClass:"wildcards"},[_c('h5',[_vm._v("Wildcards")]),_c('ValueSelector',{attrs:{"values":_vm.wildcardOptions(event).values,"colors":_vm.wildcardOptions(event).colors,"title":''},on:{"updatedValue":function($event){return _vm.$store.dispatch('updateEventStringValue', {
              value: $event.value,
              property: 'maxWildcards',
              eventIndex: eventIndex,
            })}}})],1)]),_c('section',{staticClass:"registration-open"},[_c('div',{staticClass:"sub-section left"},[_c('h5',[_vm._v("Registration opening date")]),_c('TjingDateTimePicker',{staticClass:"datepicker",attrs:{"canBeCleared":true,"readOnly":false,"options":_vm.registrationOpensTime,"dateTime":event.registrationOpenAt},on:{"update":function($event){return _vm.updateEventTimeField($event, eventIndex, 'registrationOpenAt')}}})],1),_c('div',{staticClass:"sub-section auto-open"},[_c('div',{staticClass:"header"},[_c('h6',[_vm._v("Automatically open")]),_c('DefaultToggle',{attrs:{"value":event.autoOpenRegistration,"disabled":event.registrationOpenAt == null},on:{"input":function($event){return _vm.$store.dispatch('updateBoolOnEvent', {
                property: 'autoOpenRegistration',
                eventIndex: eventIndex,
                value: $event,
              })}}})],1),_c('p',[_vm._v(" When enabled Tjing will open registration for you based on the registration opening date you’ve set. ")])])]),_c('section',{staticClass:"registration-type"},[_c('h5',[_vm._v("Registration type")]),_c('DefaultRadioButton',{attrs:{"groupname":'regtype',"value":_vm.tour.registrationMethod,"options":{
          title: 'Waiting list',
          value: 'LIST',
          description:
            'Standard registration where players are placed on a waiting list and spots are granted by the TD.',
        }},on:{"change":function($event){return _vm.$store.dispatch('updateRegistrationType', 'LIST')}}}),_c('DefaultRadioButton',{attrs:{"groupname":'regtype',"value":_vm.tour.registrationMethod,"options":{
          title: 'Groups',
          value: 'GROUPS',
          description:
            'A more casual registration where players choose their own group to play in (note: groups can still be adjusted by the TD).',
        }},on:{"change":function($event){return _vm.$store.dispatch('updateRegistrationType', 'GROUPS')}}}),_c('div',{staticClass:"registration-messages"},[_c('b',[_vm._v("Registration notes")]),_c('DefaultToggle',{attrs:{"value":_vm.tour.allowRegistrationMessage},on:{"input":function($event){return _vm.$store.dispatch('updateRegistrationMessage', $event)}}}),_c('p',[_vm._v(" Allows players to include a note when registering for an event. Only event admins can see notes. ")])],1)],1),_c('section',{staticClass:"waitinglist-type"},[_c('h5',[_vm._v("Waiting list type")]),_c('div',{staticClass:"content"},[_c('DefaultRadioButton',{staticClass:"mb-4",attrs:{"groupname":"waitinglist-type","value":event.waitinglistType,"options":{
            title: 'Separated divisions',
            value: 'DIVISION',
            description: 'Each division has its own waiting list.',
          }},on:{"change":function($event){return _vm.changeWaitinglistType(eventIndex, 'DIVISION')}}}),_c('DefaultRadioButton',{attrs:{"groupname":"waitinglist-type","value":event.waitinglistType,"options":{
            title: 'Combined divisions',
            value: 'COMBINED',
            description:
              'All players and divisions are placed in one waiting list.',
          }},on:{"change":function($event){return _vm.changeWaitinglistType(eventIndex, 'COMBINED')}}})],1)]),_c('section',{staticClass:"check-in",class:{
        error: _vm.erronousSections.includes('check-in'),
        expanded: _vm.globalSettings.checkInEnabled,
      }},[_c('h5',{on:{"click":function($event){_vm.globalSettings.checkInEnabled = !_vm.globalSettings.checkInEnabled}}},[_vm._v(" Check in "),_c('span',[_c('Chevron2',{attrs:{"direction":_vm.globalSettings.checkInEnabled ? 'up' : 'down'}})],1)]),_c('slide-up-down',{attrs:{"active":_vm.globalSettings.checkInEnabled,"duration":300}},[_c('div',{staticClass:"open"},[_c('h6',[_vm._v("Opens")]),_c('TjingDateTimePicker',{staticClass:"datepicker",attrs:{"canBeCleared":true,"readOnly":false,"options":_vm.checkInStartsTime,"dateTime":event.checkInStartsAt},on:{"update":function($event){return _vm.updateEventTimeField($event, eventIndex, 'checkInStartsAt')}}})],1),_c('div',{staticClass:"closes"},[_c('h6',[_vm._v("Closes")]),_c('TjingDateTimePicker',{staticClass:"datepicker",attrs:{"canBeCleared":true,"readOnly":false,"options":_vm.checkInEndsTime,"dateTime":event.checkInEndsAt},on:{"update":function($event){return _vm.updateEventTimeField($event, eventIndex, 'checkInEndsAt')}}})],1)])],1),_c('section',{staticClass:"pdga-tier",class:{ expanded: _vm.globalSettings.pdgaTypeEnabled }},[_c('h5',[_vm._v(" PDGA sanctioned "),_c('DefaultToggle',{on:{"input":function($event){return _vm.togglePDGASanctioned($event)}},model:{value:(_vm.globalSettings.pdgaTypeEnabled),callback:function ($$v) {_vm.$set(_vm.globalSettings, "pdgaTypeEnabled", $$v)},expression:"globalSettings.pdgaTypeEnabled"}})],1),_c('slide-up-down',{attrs:{"active":_vm.globalSettings.pdgaTypeEnabled,"duration":300}},_vm._l((_vm.pdgaEventTypes),function(type){return _c('DefaultRadioButton',{key:type.type,attrs:{"groupname":'pdgatier',"value":event.pdgaType,"options":{
            title: type.name,
            value: type.type,
          }},on:{"change":function($event){return _vm.$store.dispatch('updateEventPDGAType', {
              eventIndex: eventIndex,
              pdgaType: type.type,
            })}}})}),1)],1),_c('section',{staticClass:"nav-buttons"},[_c('div',{staticClass:"tip"},[_c('span',[_c('Tip')],1),_c('p',[_vm._v(" Everything can be edited in the Manager after your event has been saved. ")])]),_c('StandardBorderedButton',{attrs:{"title":"Back","fluid":false,"backwardsIcon":true,"desktopFluidity":true},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('StandardSolidButton',{staticClass:"forwards",attrs:{"title":"Next","fluid":false,"forwardIcon":true,"desktopFluidity":true},on:{"click":function($event){return _vm.validateAndProceed()}}})],1),_c('section',{staticClass:"start-over",on:{"click":function($event){return _vm.$refs.resetmodal.open()}}},[_vm._v(" Reset and start over ")])])}),_c('sweet-modal',{ref:"resetmodal",attrs:{"enable-mobile-fullscreen":false,"blocking":"","hide-close-button":""}},[_c('h3',[_vm._v("Reset")]),_c('p',[_vm._v(" This will remove any information you have added and take you back to the start. ")]),_c('div',{staticClass:"buttons"},[_c('StandardSolidButton',{attrs:{"title":"Cancel","fluid":false},on:{"click":function($event){return _vm.$refs.resetmodal.close()}}}),_c('StandardBorderedButton',{staticClass:"forwards",attrs:{"title":"Reset","fluid":false},on:{"click":function($event){return _vm.resetAndClose('resetmodal')}}})],1)])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"progress"},[_c('p',{staticClass:"stage"},[_vm._v("3")]),_c('div',{staticClass:"bar"},[_c('span')]),_c('p',{staticClass:"max"},[_vm._v("4")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"header"},[_c('h1',[_vm._v("Key details")]),_c('p',[_vm._v("Dive deeper now or update later in the Manager (optional)")])])}]

export { render, staticRenderFns }